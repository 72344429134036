$colorBlack: #231F20;
$colorWhite: #FFF;
$colorGrey: #666;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorGreen: #31DD6B;
$colorGreenText: #2EC922;
$colorLightGreen: lighten($colorGreen, 10);
$colorDarkGreen: darken($colorGreen, 10);
$colorBlue: #67B9FF;
$colorPink: #FF9ED4;
$colorPinkText: #FF5A9B;
$colorPurple: #AC92F0;
$colorOrange: #FB9800;
$colorYellow: #FFE400;
$colorRed: #C00;
$colorLightRed: lighten($colorRed, 10);
$colorDarkRed: darken($colorRed, 10);

html,
body {
	height: 100%;
}
body {
  	font-family: 'Quicksand', sans-serif;
	background: $colorWhite;
	color: $colorBlack;
  	font-size: 21px;
  	line-height: 26px;

}
[lang="de"] {
	body {
		font-size: 17px;
		line-height: 1.238;
	}
	input, textarea {
		font-size: 17px;
		line-height: 1.238;
		padding: {
			top: 3px;
			bottom: 2px;
		};
	}
	.triangle-1 span {
		font-size: 18px;
		line-height: 1.3;
		margin-top: 5px;
	}
}
* {
	outline: none;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba($colorBlack, 0);
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
}
img {
	vertical-align: top;
	max-width: 100%;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}
button {
	outline: none;
	&:focus {
		outline: none;
	}
}
[class^='icon-'],
[class*=' icon-'] {
	position: relative;
	vertical-align: middle;
}
// include @extend %image-bg;
%image-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 320px;
	min-height: 100%;
	overflow-x: hidden;
}
