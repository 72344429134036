.main {
	position: relative;
	width: 100%;
	min-height: 969px;
    &-inner {
        height: 100%;
    }
	&--1 {
		background: url("../img/bg-1.svg") no-repeat;
		background-position: center top;
	}
    &--2 {
        background: url("../img/bg-2-1.svg") no-repeat;
        background-position: center top;
    }
    &--3 {
        background: url("../img/bg-3.svg") no-repeat;
        background-position: center top;
    }
    &--4 {
        background: url("../img/bg-3.svg") no-repeat;
        background-position: center top;
    }
    &--5 {
        background: url("../img/bg-5-1.svg") no-repeat;
        background-position: center top;
    }
    &--6 {
        background: url("../img/bg-6.svg") no-repeat;
        background-position: center top;
    }
}

.msg {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;

	&-item {
		transition: .35s;
		text-align: center;
		border: 2px solid $colorBlack;
		background-color: $colorBlack;;
		padding: 11px 15px;
		font-weight: 600;
		color: $colorWhite;
		user-select: none;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&--transparent {
			background-color: transparent;
			box-shadow: 7px 8px 0 $colorBlack;
		}

		&--blue {
			box-shadow: 7px 8px 0 $colorBlue;
            &:hover {
                box-shadow: 9px 10px 0 $colorBlue;
            }
		}

		&--green {
			box-shadow: 7px 8px 0 $colorGreen;
            &:hover {
                box-shadow: 9px 10px 0 $colorGreen;
            }
		}

		&--pink {
			box-shadow: 7px 8px 0 $colorPink;
            &:hover {
                box-shadow: 9px 10px 0 $colorPink;
            }
		}

		&--orange {
			box-shadow: 7px 8px 0 $colorOrange;
            &:hover {
                box-shadow: 9px 10px 0 $colorOrange;
            }
		}

		&--purple {
			box-shadow: 7px 8px 0 $colorPurple;
            &:hover {
                box-shadow: 9px 10px 0 $colorPurple;
            }
		}

		&--blueText {
			color: $colorBlue;
		}

		&--greenText {
			color: $colorGreenText;
		}

		&--pinkText {
			color: $colorPinkText;
		}

		&--orangeText {
			color: $colorOrange;
		}

		&--purpleText {
			color: $colorPurple;
		}

        &-input {
            padding-right: 0!important;
            padding-left: 0!important;
            position: relative;
            i {
                position: absolute;
                left: 40px;
                top: 12px;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: $colorWhite;
                    opacity: 1;
                }
            }
            input {
                &:focus {

                    & + i {
                        &:before {
                            animation: fade 1s ease-in-out infinite;
                        }
                    }
                }
            }
        }
        &.link {
            &:hover {
                svg {
                    transform: translateX(4px);
                }
            }
            svg {
                transition: .3s;
            }
        }

		&__button {
			display: flex;
			align-items: center;
			flex-direction: row-reverse;
			& > * {
				&:not(:first-child) {
					margin-right: 30px;
				}
			}
		}
	}

	&-1 {
		position: absolute;
		top: 629px;
		left: 50%;
		max-width: 436px;
		margin-left: -218px;
		transform: translateX(102px);
		z-index: 1;

		.msg-item {
			&.active {
				height: 314px;
				position: absolute;
			}
		}

		.steps {

			& > div {
				display: none;
			}

			&.active {
				&~.msg-item {
					display: none;
				}
			}

			&.step-1 {
				div {
					&:nth-child(1) {
						display: block;
						cursor: pointer;
					}
				}
			}

			&.step-2 {
				div {
					position: relative;

					&:before {
						content: '';
						position: absolute;
                        left: -1px;
                        top: 20px;
						width: 3px;
						height: 15px;
						background-color: $colorWhite;
						opacity: 0;
						animation: fade 1s ease-in-out infinite;
                        transition: .3s;
                        display: none;
					}

					&:nth-child(2) {
						padding: 10px 0;
						display: flex;
						height: 100%;
						flex-direction: column;
						align-items: center;
						justify-content: space-between;
                    }

                    .submit-btn {
						&:not(:last-child) {
							margin-left: 100px;
						}
                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            color: $colorOrange;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s transform;
                            }
                        }
                    }

					.cancel-btn {
						position: absolute;
						right: 50%;
						bottom: 10px;
						display: flex;
						align-items: center;
						color: $colorOrange;
						margin-right: 15px;
						cursor: pointer;
						&:hover {
							svg {
								transform: rotate(90deg);
							}
						}

						svg {
							flex-shrink: 0;
							margin-right: 7px;
							position: relative;
							top: 2px;
							transition: .3s transform;
						}
					}
				}
			}

			&.step-3 {
				div {
					position: relative;

					&:nth-child(3) {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px 0;
						height: 100%;

						span {
							&:nth-child(2) {
								display: flex;
								align-items: center;
								color: $colorOrange;

								svg {
									flex-shrink: 0;
									margin-right: 7px;
									position: relative;
									top: 2px;
								}
							}
						}

						.btn-wrapper {
							display: flex;
							position: absolute;
							left: calc(100% + 30px);
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}

			}

			&.step-4 {
				div {
					position: relative;

					&:nth-child(4) {
						padding: 10px 0;
						display: flex;
						height: 100%;
						flex-direction: column;
						align-items: center;
						justify-content: space-between;

						a {
							display: flex;
							align-items: center;
							color: $colorPink;
							cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
                                transition: .3s;
							}
						}
					}
				}
			}

			&.step-5 {
				div {
					position: relative;

					&:nth-child(5) {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px 0;
						height: 100%;

						span {
							&:nth-child(2) {
								display: flex;
								align-items: center;
								color: $colorOrange;

								svg {
									flex-shrink: 0;
									margin-right: 7px;
									position: relative;
									top: 2px;
								}
							}
						}

						.btn-wrapper {
							display: flex;
							position: absolute;
							left: calc(100% + 30px);
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
			}

			&.step-6 {
				div {
					position: relative;

					&:nth-child(6) {
						padding: 10px 0;
						display: flex;
						height: 100%;
						flex-direction: column;
						align-items: center;
						justify-content: center;
                        .link {
                            display: flex;
                            align-items: center;
                            color: $colorPink;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s;
                            }
                        }
					}
				}
			}

			&.step-7 {
				div {
					position: relative;

					&:nth-child(7) {
						padding: 10px 0;
						display: flex;
						height: 100%;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						.link {
							display: flex;
							align-items: center;
							color: $colorGreen;
							cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
                                transition: .3s;
							}
						}
					}
				}
			}

			&.step-8 {
				width: 100%;
				div {
					position: relative;

					&:nth-child(8) {
						padding: 10px 0;
						display: flex;
						height: 100%;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						.link {
							display: flex;
							align-items: center;
							color: $colorGreen;
							cursor: pointer;

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
							}
						}
					}
				}
			}
		}
	}
	&-2 {
		position: absolute;
		top: 261px;
		left: 50%;
		margin-left: -286px;
		transform: translateX(-281px);
		align-items: flex-end;
		width: auto;

		.msg-item {
			display: flex;
			justify-content: flex-end;
			padding-right: 67px;

			&:nth-child(1),
			&:nth-child(2) {
				width: 428px;
			}

			&:nth-child(3) {
				width: 573px;
				position: relative;
				svg {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 70px;
				}
			}
		}
	}
    &-3 {
        position: absolute;
        top: 18px;
        left: 50%;
        margin-left: -265px;
        transform: translateX(-6px);
        width: auto;

        .msg-item {
            display: flex;
            justify-content: center;
            width: 531px;
            color: $colorBlue;
            padding: 14px 15px;
        }
    }
    &-4 {
        position: absolute;
        top: 189px;
        left: 50%;
        margin-left: -322px;
        transform: translateX(-6px);
        width: auto;

        .msg-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 644px;
            color: #231F20;
            background-color: #FFE400;
            padding: 19px 15px;
            transition: none;
            &:hover {
                background-color: #231F20;
                box-shadow: 7px 8px 0 #67B9FF;
                a {
                    &:nth-child(1) {
                        color: #67B9FF;
                    }
                    &:nth-child(2) {
                        color: #F5C8FF;
                    }
                    &:nth-child(3) {
                        color: #FB9800;
                    }
                    &:nth-child(4) {
                        color: #31DD6B;
                    }
                    &:nth-child(5) {
                        color: #AC92F0;
                    }
                    &:nth-child(6) {
                        color: #FFE400;
                    }
                    &:nth-child(7) {
                        color: #67B9FF;
                    }
                    &:nth-child(8) {
                        color: #FF869B;
                    }
                    span {
                        &:before {
                            filter: brightness(2);
                        }
                    }
                }
            }
            a {
                position: relative;
                display: flex;
                text-align: center;
                flex-direction: column;
                color: #231F20;
                transition: none;
                span {
                    &:first-child {
                        &:before {
                            content: '';
                            background: url(../img/ico-link-arrow.svg) no-repeat center top;
                            width: 9px;
                            height: 15px;
                            position: relative;
                            display: inline-block;
                            margin-right: 15px;
                        }
                    }
                }
                &:hover {
                    span {
                        font-weight: 700;
                    }
                }
            }
			&__preview {
				position: absolute;
				top: 0;
				left: 100%;
				bottom: 0;
				max-width: calc((100vw - 644px) / 2);
				pointer-events: none;
				overflow: hidden;
				img	{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					transition: opacity 0.35s;
					opacity: 0;
					&.show {
						opacity: 1;
					}
				}
			}
        }
    }
    &-5 {
        position: absolute;
        top: 555px;
        left: 50%;
        max-width: 442px;
        margin-left: -221px;
        transform: translateX(92px);
        z-index: 1;

        .msg-item {
            padding: 12px 15px;
            &:not(:last-child) {
                margin-bottom: 17px;
            }
            &.active {
				display: flex;
				flex-direction: column;
                height: 384px;
                position: absolute;
            }
        }

        .steps {

            & > div {
                display: none;
            }

            &.active {
                &~.msg-item {
                    display: none;
                }
            }

            &.step-1 {
                & > div {
					flex: 1 0 0;
                    &:nth-child(1) {
                        display: block;
                        cursor: pointer;
                    }
                }
            }

            &.step-2 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(2) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;

                        .btn-wrapper {
                            display: flex;
                            position: absolute;
                            left: calc(100% + 30px);
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            &.step-3 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(3) {
                        padding: 10px 0;
                        display: flex;
                        height: 100%;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        a {
                            display: flex;
                            align-items: center;
                            color: $colorBlue;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s;
                            }
                        }


						.cancel-btn {
							position: relative;
							display: flex;
							align-items: center;
							color: $colorBlue;
							cursor: pointer;
							&:hover {
								svg {
									transform: rotate(90deg);
								}
							}

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
								transition: .3s transform;
							}
						}
                    }
                }
            }

            &.step-4 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(4) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                        height: 100%;

                        span {
                            &:nth-child(2) {
                                display: flex;
                                align-items: center;
                                color: $colorOrange;

                                svg {
                                    flex-shrink: 0;
                                    margin-right: 7px;
                                    position: relative;
                                    top: 2px;
                                }
                            }
                        }

                        .btn-wrapper {
                            display: flex;
                            position: absolute;
                            left: calc(100% + 30px);
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            &.step-5 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(5) {
                        padding: 10px 0;
                        display: flex;
                        height: 100%;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .link {
                            display: flex;
                            align-items: center;
                            color: $colorPink;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s;
                            }
                        }

						.cancel-btn {
							position: relative;
							display: flex;
							align-items: center;
							color: $colorPink;
							cursor: pointer;
							&:hover {
								svg {
									transform: rotate(90deg);
								}
							}

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
								transition: .3s transform;
							}
						}
                    }
                }
            }

            &.step-6 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(6) {
                        padding: 10px 0;
                        display: flex;
                        height: 100%;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .link {
                            display: flex;
                            align-items: center;
                            color: $colorGreen;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s;
                            }
                        }


						.cancel-btn {
							position: relative;
							display: flex;
							align-items: center;
							color: $colorGreen;
							cursor: pointer;
							&:hover {
								svg {
									transform: rotate(90deg);
								}
							}

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
								transition: .3s transform;
							}
						}
                    }
                }
            }

            &.step-7 {
                width: 100%;
                .msg {
                    height: 100%;
                    justify-content: center;
                    a {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
                div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(7) {
                        padding: 10px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 384px;
                    }
                }
            }

            &.step-8 {
                & > div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(2) {
                        padding: 10px 0;
                        display: flex;
                        height: 100%;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .link {
                            display: flex;
                            align-items: center;
                            color: $colorPurple;
                            cursor: pointer;
                            &:hover {
                                svg {
                                    transform: translateX(4px);
                                }
                            }

                            svg {
                                flex-shrink: 0;
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                                transition: .3s;
                            }
                        }

						.cancel-btn {
							position: relative;
							display: flex;
							align-items: center;
							color: $colorPurple;
							cursor: pointer;
							&:hover {
								svg {
									transform: rotate(90deg);
								}
							}

							svg {
								flex-shrink: 0;
								margin-right: 7px;
								position: relative;
								top: 2px;
								transition: .3s transform;
							}
						}
                    }
                }
            }

            &.step-9 {
                width: 100%;
                .msg {
                    height: 100%;
                    justify-content: center;
                    a {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
                div {
                    position: relative;
					flex: 1 0 0;

                    &:nth-child(3) {
                        padding: 10px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 384px;
                    }
                }
            }
        }
    }
    &-6 {
        position: absolute;
        top: 95px;
        left: 50%;
        max-width: 531px;
        margin-left: -265px;
        transform: translateX(-368px);
        z-index: 1;
        width: 100%;
        align-items: center;
        .msg-item {
            width: 100%;
            padding: 13px 15px;
            &:nth-child(even) {
                width: 442px;
            }
            &:nth-child(1) {
                color: $colorPurple;
            }
            &:nth-child(2) {
                color: $colorWhite;
                background-color: $colorGreen;
            }
            &:nth-child(3) {
                color: $colorBlack;
            }
            &:nth-child(4) {
                color: $colorWhite;
                background-color: $colorPurple;
            }
            &:nth-child(5) {
                color: $colorBlack;
            }
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }
    &-7 {
        position: absolute;
        top: 165px;
        left: 50%;
        max-width: 531px;
        margin-left: -265px;
        transform: translateX(389px);
        z-index: 1;
        width: 100%;
        align-items: center;
        .msg-item {
            width: 442px;
            padding: 13px 15px;
            &:nth-child(even) {
                width: 100%;
            }
            &:nth-child(1) {
                color: $colorWhite;
                background-color: $colorPink;
            }
            &:nth-child(2) {
                color: $colorBlack;
            }
            &:nth-child(3) {
                color: $colorWhite;
                background-color: $colorOrange;
            }
            &:nth-child(4) {
                color: $colorBlack;
            }
            &:nth-child(5) {
                color: $colorWhite;
                background-color: $colorBlue;
            }
            &:nth-child(6) {
                color: $colorBlack;
            }
            &:nth-child(7) {

            }
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }
    &-8 {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 476px;
        transform: translate(-50%,-50%);
        z-index: 2;
        width: 100%;
        .msg-item {
            width: 100%;
            &:nth-child(2) {
                padding: 15px;
            }
            &:not(:last-child) {
                color: $colorBlack;
            }
        }
    }
    &-9 {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: calc(100% - 60px);
        height: 581px;
        transform: translate(-50%,-50%);
        z-index: 2;
        width: 1033px;
        margin-top: -17px;
        background-color: $colorWhite;
        .video {
            width: 100%;
            height: 100%;
        }
        .msg-item {
            width: 100%;
            &:nth-child(1) {
                padding: 0;
                height: 100%;
            }
            .msg {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 442px;
                .msg-item {
                    display: flex;
                    height: 151px;
                    padding: 27px;
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: $colorBlack;
                        padding: 20px;
                    }
                    &:not(:last-child) {
                        margin-bottom: 24px;
                    }
                    &:nth-child(1) {
                        span {
                            background-color: $colorYellow;
                        }
                    }
                    &:nth-child(2) {
                        span {
                            background-color: $colorBlue;
                        }
                    }
                }
            }
        }
    }
    &-10 {
        position: absolute;
        top: 74px;
        left: 50%;
        max-width: 476px;
        margin-left: -238px;
        transform: translateX(0px);
        z-index: 1;
        width: 100%;
        align-items: center;
        .msg-item {
            &.msg-item--transparent {
                color: $colorBlack;
            }
        }
    }
    &-thx {
        display: none;
        width: 100%!important;
        position: static!important;
        transform: translate(0)!important;
        .msg-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto!important;
            width: 100%;
            padding: 25px;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                width: 100%;
            }
            &:nth-child(1) {
                span {
                    background-color: $colorYellow;
                }
            }
            &:nth-child(2) {
                span {
                    background-color: $colorBlue;
                }
            }
        }
    }
    &-form {
        height: 100%;
        display: flex;
		flex: 1 0 0;
        flex-direction: column;
        align-items: center;
        padding-top: 5px;
        textarea {
			flex: 1 0 0;
            width: 100%;
            height: 100%;
            padding: 0;
            &::placeholder {
                text-align: center!important;
                color: $colorWhite!important;
            }
        }
        button {
            background: none;
            border: none;
            outline: none;
        }
    }
    &-withform {
        &.active {
            &:before {
                opacity: 0!important;
                pointer-events: none;
                animation: auto!important;
            }
        }
    }
}

.btn {
	width: 100%;
	min-width: 210px;
	border: 2px solid $colorBlack;
	background-color: $colorBlack;
	text-align: center;
	font-weight: 600;
	padding: 10px 15px;
	cursor: pointer;
	color: $colorWhite;
	user-select: none;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	&--transparent {
		background-color: transparent;
		box-shadow: 7px 8px 0 $colorBlack;
		color: $colorBlack;
	}

	&--blue {
		box-shadow: 7px 8px 0 $colorBlue;
        &:hover {
            box-shadow: 9px 10px 0 $colorBlue;
        }
	}

	&--green {
		box-shadow: 7px 8px 0 $colorGreen;
        &:hover {
            box-shadow: 9px 10px 0 $colorGreen;
        }
	}

	&--pink {
		box-shadow: 7px 8px 0 $colorPink;
        &:hover {
            box-shadow: 9px 10px 0 $colorPink;
        }
	}

	&--orange {
		box-shadow: 7px 8px 0 $colorOrange;
        &:hover {
            box-shadow: 9px 10px 0 $colorOrange;
        }
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.triangle {
	position: absolute;
	user-select: none;

	&-1 {
		left: 50%;
		margin-left: -78px;
		top: 458px;
		transform: translateX(228px);

		span {
			position: absolute;
			top: 50%;
			left: calc(50% - 5px);
			transform: translate(-50%, -50%);
			width: 65px;
			text-align: center;
			font-size: 22px;
			line-height: 28px;
			font-weight: 700;
			color: $colorWhite;
		}

		svg {
			path {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					fill: $colorBlack;
				}
			}
		}
	}
	&-2 {
		display: none;
	}
    &-3 {
        left: 50%;
        margin-left: -70px;
        top: 483px;
        transform: translateX(-240px);
        width: 140px;

        span {
            position: absolute;
            top: 50%;
            left: calc(50% - 0px);
            transform: translate(-50%, -50%);
            width: 55px;
            text-align: center;
            font-size: 21px;
            line-height: 22px;
            font-weight: 700;
            color: $colorWhite;
            padding-top: 20px;
        }

		svg {
			path {
				&:nth-child(1) {
					display: none;
				}
				&:nth-child(2) {
					fill: $colorBlack;
				}
			}
		}
    }
    &-4 {
        display: none;
    }
	&-5 {
		display: none;
	}
}

.bg {
	&-1 {
		display: none;
	}
    &-2 {
        display: none;
    }
    &-4 {
        display: none;
    }
    &-5 {
        display: none;
    }
    &-6 {
        display: none;
    }
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.95);
}

input, textarea {
    position: relative;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    transition: .4s;
    background-color: transparent;
    color: $colorWhite;
    padding: 0 76px;
    border: none;
    resize: none;
    outline: none;
    width: 100%;
    font-family: "Quicksand",sans-serif;
    ::placeholder {
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
        color: rgba(255,255,255,.5);
    }
}

button {
    cursor: pointer;
}

.btn-wrapper {
    &>div {
        opacity: 0;
        transform: translateX(-100%);
        &:nth-child(1) {
            transition: .4s ease;
        }
        &:nth-child(2) {
            transition: 1.2s ease;
        }
    }
    &.active {
        &>div {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.calendar {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0!important;
}

.ui-widget {
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    color: $colorBlack;
    font-size: 21px;
    line-height: 26px;
    border: 2px solid #231f20!important;
    box-shadow: 7px 8px 0 #231f20;
    border-radius: 0;
    padding: 0;
    background: $colorWhite!important;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 7px 0;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #231f20!important;
    background: $colorYellow;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    cursor: pointer;
    span {
        display: none;
    }
    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-size: 22px;
        line-height: 0;
        color: $colorBlack;
        transition: .3s;
    }
    &:hover {
        &:before {
            color: $colorGrey;
        }
    }
}

.ui-datepicker .ui-datepicker-prev {
    left: 4px!important;
    &:before {
        content: '<';
    }
}

.ui-datepicker .ui-datepicker-next {
    right: 4px!important;
    &:before {
        content: '>';
    }
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover {
    background: none;
    border-radius: 0;
    border: none;
}

.ui-datepicker {
    td {
        padding: 0;
        transition: .3s;
    }
    th {
        padding: 4px;
    }
}

.ui-state-default, .ui-widget-content .ui-state-default {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    text-align: center;
    border: none;
    padding: 8px;
    color: $colorBlack;
    font-weight: 600;
}

.ui-datepicker-today {
    a {
        background: $colorYellow!important;
    }
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight {
    border: none;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-datepicker-current-day a {
    border: none;
    background: $colorGreen!important;
    color: $colorBlack;
    transition: .3s;
}

.ui-datepicker table {
    font-size: 22px;
    margin: 10px auto;
    width: 92%;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
    opacity: .3;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
    color: $colorBlack;
    opacity: 1;
}

form {
    width: 100%;
    label.error {
        display: none!important;
    }
    input.error {
        color: red;
        ::placeholder {
            color: red;
        }
    }
}

.soc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
		width: 37px;
		height: 37px;
        transition: .3s;
        &:not(:last-child) {
            margin-right: 30px;
        }
        &:hover {
            opacity: .7;
        }
		svg {
			max-width: 100%;
		}
    }
}

.preloader {
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
    background-color: #fff;
    svg {
        width: 140px;
        height: 334px;
		&:not(:last-child) {
			margin-bottom: 17.6vh;
		}
        circle {
            transition: 3s ease-in-out;
            opacity: 0;
        }
    }

	&-box {
		position: relative;
		&__title {
			position: relative;
			font-weight: 600;
			text-align: center;
			&:not(:last-child) {
				margin-bottom: 35px;
			}
		}
		&__button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				background: $colorBlack;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 210px;
				height: 46px;
				color: $colorWhite;
				font-weight: 600;
				text-align: center;
				transition: box-shadow .35s;
				&:not(:last-child) {
					margin-right: 32px;
				}
				&:nth-child(1) {
					box-shadow: 7px 8px 0 $colorGreen;
					&:hover {
						box-shadow: 9px 10px 0 $colorGreen;
					}
				}
				&:nth-child(2) {
					box-shadow: 7px 8px 0 $colorOrange;
					&:hover {
						box-shadow: 9px 10px 0 $colorOrange;
					}
				}
			}
		}
	}
}

.flag {
    position: absolute;
    left: 50%;
    overflow: hidden;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
        z-index: 1;
        animation: wave 2.5s infinite linear;
    }

    &-1 {
        margin-left: -51px;
        transform: translateX(-739px);
        top: 8px;
    }
    &-2 {
        margin-left: -48px;
        transform: translateX(-780px);
        top: 48px;
    }
    &-3 {
        margin-left: -42px;
        transform: translateX(-780px);
        top: 208px;
    }
}

.cloud {
    position: absolute;
    left: 50%;
    animation: cloud 30s infinite ease-in-out;

    &-1 {
        margin-left: 422px;
        top: 537px;
    }
    &-2 {
        margin-left: 222px;
        top: 37px;
    }
    &-3 {
        margin-left: 522px;
        top: 197px;
    }
	&-4 {
		margin-left: -500px;
		top: 725px;
	}
	&-5 {
		margin-left: 450px;
		top: 347px;
	}
	&-6 {
		margin-left: 450px;
		top: 20px;
	}
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes wave {
    0% {
        transform: translateX(20%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes cloud {
    0% {
        transform: translateX(-170px);
    }
    50% {
        transform: translateX(170px);
    }
    100% {
        transform: translateX(-170px);
    }
}

.back {
    position: fixed;
	background: $colorBlack;
    top: 5%;
    right: 5%;
    z-index: 11;
	color: $colorWhite;
	font-weight: 700;
	box-shadow: 7px 8px 0 #a0a0a0;
	transition: box-shadow 0.35s;
	padding: 12px 15px;
    cursor: pointer;
	&:hover {
		box-shadow: 9px 10px 0 #a0a0a0;
	}
    svg {
        width: 100%;
        height: 100%;
        path {
            transition: .4s;
            fill: $colorBlack;
        }
    }
    &:hover {
        svg {
            path {
                fill: $colorPurple;
            }
        }
    }
}
