@media screen and (max-width: 1279px) {
	.msg-item__preview {
		display: none;
	}
}
@media screen and (max-width: 767px) {
	.preloader {
		svg {
			&:not(:last-child) {
				margin-bottom: 10vh;
			}
		}
		&-box {
			&__title {
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			&__button {
				flex-direction: column;
				a {
					&:not(:last-child) {
						margin-right: 0;
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}
@media (orientation: portrait) {
	[lang="de"] {
		input, textarea {
			font-size: 4vw;
			line-height: 1.2;
			padding: 0 8vw;
		}
	}
    .wrapper {
        overflow: hidden;
    }
	.preloader svg {
		width: 110px;
		height: 262px;
	}
    .main {
        height: auto;
        min-height: auto;
        padding: 25px;
        &--1 {
            background: none;
        }
        &--2 {
            background: none;
        }
        &--3 {
            background: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        &--4 {
            background: none;
            .overlay {
                display: none;
            }
        }
        &--5 {
            background: none;
        }
        &--6 {
            background: none;
        }
        &.form-send {
            .bg-5-1,
            form {
                display: none;
            }
            .bg-5-2,
            .bg-5-3 {
                display: block;
            }
        }
    }
    .msg {
        &-item {
            width: 100%!important;
            justify-content: center!important;
            padding: 9px 10px!important;
            font-size: 4vw;
            line-height: 1.2;
            &:not(:last-child) {
                margin-bottom: 4vw;
            }
            &--transparent {
                background-color: transparent;
                box-shadow: 4px 4px 0 $colorBlack;
            }

            &--blue {
                box-shadow: 4px 4px 0 $colorBlue;
                &:hover {
                    box-shadow: 6px 6px 0 $colorBlue;
                }
            }

            &--green {
                box-shadow: 4px 4px 0 $colorGreen;
                &:hover {
                    box-shadow: 6px 6px 0 $colorGreen;
                }
            }

            &--pink {
                box-shadow: 4px 4px 0 $colorPink;
                &:hover {
                    box-shadow: 6px 6px 0 $colorPink;
                }
            }

            &--orange {
                box-shadow: 4px 4px 0 $colorOrange;
                &:hover {
                    box-shadow: 6px 6px 0 $colorOrange;
                }
            }

            &--purple {
                box-shadow: 4px 4px 0 $colorPurple;
                &:hover {
                    box-shadow: 6px 6px 0 $colorPurple;
                }
            }
            &-input {
                i {
					top: 1.3889vw;
                    left: 4vw;
                }
            }

			&__button {
				& > * {
					&:not(:first-child) {
						margin-right: 5.5556vw;
					}
				}
			}

			&.link {
				svg {
					position: relative;
					top: 2px;
					width: 5vw;
					height: auto;
					margin-right: 1.9445vw;
				}
			}
        }
        &-1 {
            position: static;
            margin-left: 0;
            transform: translateX(100%);
            align-items: center;
            width: 100%;
            max-width: 100%;
            transition: .6s transform;
            &.active {
                transform: translateX(0);
            }
            .msg-item {
                &.active {
                    position: static;
					display: flex;
					flex-direction: column;
					min-height: 55vw;
					height: auto;
                }
            }
            .steps {
				&.step {
					&-2 {
						div {
							&:nth-child(2) {
								flex: 1 0 0;
							}
							.submit-btn {
								&:nth-child(2) {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
								&:not(:last-child) {
									margin-left: 27.7778vw;
								}
							}
							.cancel-btn {
								margin-right: 4.1667vw;
								svg {
									top: 0;
									width: 3.3334vw;
									height: auto;
									margin-right: 1.9445vw;
								}
							}
						}
					}
					&-4 {
						div {
							flex: 1 0 0;
							&:nth-child(4) {
								a {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
					&-5 {
						div {
							flex: 1 0 0;
							&:nth-child(5) {
								flex: 1 0 0;
							}
						}
					}
					&-6 {
						div {
							flex: 1 0 0;
							&:nth-child(6) {
								.link {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
					&-7 {
						div {
							flex: 1 0 0;
							&:nth-child(7) {
								.link {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
				}
                .btn-wrapper {
                    left: 50% !important;
                    top: -28vw !important;
                    transform: translate(-50%, 0)!important;
                }
            }
        }
        &-2 {
            position: static;
            margin-left: 0;
            transform: translateX(0);
            align-items: center;
            width: 100%;
            .msg-item {
                svg {
                    display: none;
                }
            }
        }
        &-3 {
            position: static;
            margin-left: 0;
            transform: translateX(0);
            align-items: center;
            width: 100%;
            margin-bottom: 5vw;
        }
        &-4 {
            position: static;
            margin-left: 0;
            transform: translateX(0);
            align-items: center;
            width: 100%;
            margin-bottom: 6vw;
            .msg-item {
                font-size: 4.5vw;
                padding: 3vw!important;
                background-color: #231F20;
                box-shadow: 5px 5px 0 #67B9FF;
                a {
                    &:nth-child(1) {
                        color: #67B9FF;
                    }
                    &:nth-child(2) {
                        color: #F5C8FF;
                    }
                    &:nth-child(3) {
                        color: #FB9800;
                    }
                    &:nth-child(4) {
                        color: #31DD6B;
                    }
                    &:nth-child(5) {
                        color: #AC92F0;
                    }
                    &:nth-child(6) {
                        color: #FFE400;
                    }
                    &:nth-child(7) {
                        color: #67B9FF;
                    }
                    &:nth-child(8) {
                        color: #FF869B;
                    }
                    span {
                        &:first-child {
                            &:before {
                                width: 2vw;
                                height: 3vw;
                                margin-right: 1vw;
                                background-size: 100% 100%!important;
                                filter: brightness(2);
                            }
                        }
                    }
                }
            }
        }
        &-5 {
            position: static;
            margin-left: 0;
			transform: translateX(100%);
            align-items: center;
            width: 100%;
            max-width: 100%;
			margin-bottom: 0;
			transition: .6s transform;
			&.active {
				transform: translateX(0);
			}
            .msg-item {
                &.active {
                    position: relative;
                    height: 83vw;
                    margin-bottom: 0!important;
                    display: flex;
                    align-items: center;
                    &>div {
                        position: static;
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 3.9vw;
                }
            }
            .steps {
				&.step {
					&-3 {
						& > div {
							&:nth-child(3) {
								a {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
								.cancel-btn {
									svg {
										top: 0;
										width: 3.3334vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
					&-5 {
						& > div {
							&:nth-child(5) {
								.link {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
								.cancel-btn {
									svg {
										top: 0;
										width: 3.3334vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
					&-6 {
						& > div {
							&:nth-child(6) {
								.link {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
								.cancel-btn {
									svg {
										top: 0;
										width: 3.3334vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
					&-8 {
						& > div {
							&:nth-child(2) {
								.link {
									svg {
										top: 0;
										width: 5vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
								.cancel-btn {
									svg {
										top: 0;
										width: 3.3334vw;
										height: auto;
										margin-right: 1.9445vw;
									}
								}
							}
						}
					}
				}
                .btn-wrapper {
					left: 50% !important;
					top: -28vw !important;
					transform: translate(-50%, 0) !important;
                    height: auto!important;
                    padding: 0!important;
                }
            }
        }
        &-6 {
            position: static;
            transform: translateX(0);
            margin-left: 0;
            margin-bottom: 4vw;
            .msg-item {
                &:not(:last-child) {
                    margin-bottom: 4vw;
                }
                &:nth-child(3),
                &:nth-child(5) {
                    br {
                        display: none;
                    }
                }
            }
        }
        &-7 {
            position: static;
            transform: translateX(0);
            margin-left: 0;
            .msg-item {
                &:not(:last-child) {
                    margin-bottom: 4vw;
                }
                &:nth-child(2),
                &:nth-child(4) {
                    br {
                        display: none;
                    }
                }
            }
        }
        &-8 {
            position: static;
            transform: translate(0);
            max-width: 100%;
            width: 100%;
            margin: 4vw 0;
            .link {
                box-shadow: 4px 4px 0 $colorOrange;
            }
        }
        &-9 {
            position: static;
            width: 100%;
            height: 76vw;
            transform: translate(0);
            margin: 4vw 0;
            max-width: 100%;
            &>.msg-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0!important;
                box-shadow: 4px 4px 0 $colorOrange;
            }
            .msg-main {
                &.active {
                    box-shadow: none;
                    border: none;
                }
            }
            .msg-video {
                width: 100%!important;
                position: static!important;
                transform: translate(0)!important;
                a {
                    height: auto!important;
                    padding: 4vw!important;
                    &:not(:last-child) {
                        margin-bottom: 4vw;
                    }
                }
            }
        }
        &-10 {
            position: static;
            transform: translate(0);
            max-width: 100%;
            width: 100%;
            margin: 0;
            .calendar {
                padding: 0!important;
            }
        }
    }
    .triangle {
        &-1 {
            display: none;
        }
        &-2 {
            display: flex;
            margin: 4vw auto;
            width: 29vw;
            position: relative;
            justify-content: center;
            align-items: center;
            svg {
                width: 100%;
                height: 100%;
                transition: .5s;
				path {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						fill: $colorBlack;
					}
				}
            }
            span {
                position: absolute;
                font-size: 4vw;
                line-height: 1.2;
                color: $colorWhite;
                width: 100%;
                text-align: center;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 2vw;
                left: -5px;
                transition: .3s;
            }
            &:after {
                content: "";
                position: absolute;
                width: calc(100vw - 50px);
                background: url(../img/bg-1-mob-2.svg) no-repeat center top;
                background-size: 100%;
                left: 50%;
                transform: translateX(-50%);
                height: 120vw;
                top: 0;
            }
            &.hidden {
                svg, span {
                    opacity: 0;
                }
            }
        }
        &-3 {
            display: none;
        }
        &-4 {
            display: flex;
            margin: 4vw auto 36vw;
            width: 29vw;
            position: relative;
            justify-content: center;
            align-items: center;
            transform: translateX(2vw) translateY(4vw);
            &.unactive {
                svg, span {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            svg {
                width: 100%;
                height: 100%;
                transition: .5s;
				path {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						fill: $colorBlack;
					}
				}
            }
            span {
                position: absolute;
                font-size: 4vw;
                line-height: 1.2;
                color: $colorWhite;
                width: 100%;
                text-align: center;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 2vw;
                left: -5px;
                transition: .3s;
            }
            &:after {
                content: "";
                position: absolute;
                width: calc(100vw - 50px);
                background: url(../img/bg-2-mob-1.svg) no-repeat center top;
                background-size: 100%;
                left: 50%;
                transform: translateX(-50%);
                height: 120vw;
                top: 0;
                margin-left: -2vw;
                margin-top: -5vw;
            }
            &.hidden {
                svg, span {
                    opacity: 0;
                }
            }
        }
		&-5 {
			display: flex;
			margin: 4vw auto;
			width: 29vw;
			position: relative;
			justify-content: center;
			align-items: center;
			svg {
				width: 100%;
				height: 100%;
				transition: .5s;
				path {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) {
						fill: $colorBlack;
					}
				}
			}
			span {
				position: absolute;
				font-size: 4vw;
				line-height: 1.2;
				color: $colorWhite;
				width: 100%;
				text-align: center;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 2vw;
				left: -5px;
				transition: .3s;
			}
			&:after {
				content: "";
				position: absolute;
				width: calc(100vw - 50px);
				background: url(../img/bg-1-mob-2.svg) no-repeat center top;
				background-size: 100%;
				left: 50%;
				transform: translateX(-50%);
				height: 120vw;
				top: 0;
			}
			&.hidden {
				svg, span {
					opacity: 0;
				}
			}
		}
    }
    .bg {
        &-1 {
            display: block;
            width: 100%;
            margin-bottom: 5vw;
        }
        &-2 {
            display: block;
            width: 100%;
            margin-bottom: 5vw;
        }
        &-4 {
            display: block;
            width: 100%;
        }
        &-5 {
            width: 100%;
            &-1 {
                display: block;
                margin-top: 5vw;
            }
            &-2 {
                margin-bottom: 4vw;
            }
            &-3 {
                margin-top: 4vw;
            }
        }
        &-6 {
            display: block;
            width: 100%;
        }
    }
    .btn {
        font-size: 4vw;
        line-height: 1.2;
        min-width: auto;
        padding: 6px 14px;
        white-space: nowrap;
        &:not(:last-child) {
            margin-bottom: 3vw;
        }
        &--transparent {
            box-shadow: 4px 4px 0 $colorBlack;
        }

        &--blue {
            box-shadow: 4px 4px 0 $colorBlue;
            &:hover {
                box-shadow: 6px 6px 0 $colorBlue;
            }
        }

        &--green {
            box-shadow: 4px 4px 0 $colorGreen;
            &:hover {
                box-shadow: 6px 6px 0 $colorGreen;
            }
        }

        &--pink {
            box-shadow: 4px 4px 0 $colorPink;
            &:hover {
                box-shadow: 6px 6px 0 $colorPink;
            }
        }

        &--orange {
            box-shadow: 4px 4px 0 $colorOrange;
            &:hover {
                box-shadow: 6px 6px 0 $colorOrange;
            }
        }
    }
    .mobile {
        display: flex;
        &.active {
            .mobile-box {
                transform: translateX(-100%);
                &:nth-child(1) {
                    opacity: 0;
                    z-index: -1;
                }
                &:nth-child(2) {
                    opacity: 1;
                    position: static;
                    z-index: 1;
                }
            }
        }
        &-box {
            position: relative;
            width: calc(100vw - 50px);
            flex-shrink: 0;
            transition: .35s linear;
            z-index: 1;
            &:nth-child(2) {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }
    }
    input, textarea {
        font-size: 4vw;
        line-height: 1.2;
        padding: 0 8vw;
        ::placeholder {
            font-size: 4vw;
            line-height: 1.2;
        }
    }
    .ui-widget {
        font-size: 4vw;
        line-height: 1.2;
        box-shadow: 4px 4px 0 #231f20;
        td, th {
            font-size: 4vw;
            line-height: 1.2;
        }
    }
    .ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
        &:before {
            font-size: 4vw;
            line-height: 1.2;
        }
    }
    .soc {
        a {
            width: 7.5vw;
            height: 7.5vw;
            &:not(:last-child) {
                margin-right: 5vw;
            }
        }
    }
    .flag,
    .cloud {
        display: none;
    }
	.p-portrait-back {
		padding-top: 10.4167vh;
	}
	.back {
		top: 2.6042vh;
		left: 25px;
		right: 25px;
		font-size: 4vw;
		text-align: center;
		line-height: 1.2;
		padding: 9px 2.7778vw;
		box-shadow: 4px 4px 0 #a0a0a0;
		&:hover {
			box-shadow: 6px 6px 0 #a0a0a0;
		}
	}
}
